.card {
  background: white;
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgb(0 0 0);
  display: flex;
  flex-direction: column;
  height: calc((var(--card-width)) * 8 / 5);
  padding: 20px;
  position: relative;
  width: var(--card-width);
  z-index: 2;
}

.card:after {
  background-image: url('../../img/black_texture.png');
  border-radius: 10px;
  content: '';
  height: calc(100% - 20px);
  left: 10px;
  position: absolute;
  top: 10px;
  width: calc(100% - 20px);
  z-index: -1;
}

.header_card_back {
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.name_card {
  align-items: center;
  color: white;
  display: flex;
  font-family: 'MuseoModerno' , cursive;
  font-size: 27px;
  font-weight: 600;
  height: 54px;
  justify-content: center;
  line-height: 1;
  margin-top: 230px;
  padding: 0 10px;
  text-align: center;
  width: 100%;

}

.card_image_container {
  background: var(--secondary-color);
  border-radius: 10px 10px 0 0;
  height: 200px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  top: 10px;
  width: calc(100% - 20px);
}

.super_seal {
  background: url('../../img/selo.svg');
  background-position: center;
  background-size: contain;
  height: calc(var(--card-width) * 1 / 4);
  left: 10px;
  overflow: hidden;
  position: absolute;
  text-indent: -1000%;
  top: 10px;
  width: calc(var(--card-width) * 1 / 4);
}

.rarity {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  color: var(--primary-color-dark);
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 8px;
  position: absolute;
  top: 210px;
  transform: translate(-50%, -50%);
  width: 150px;
}

.description_card {
  align-items: flex-start;

  /* background-color: var(--primary-color); */
  color: white;
  display: flex;
  flex-grow: 1;
  font-size: 18px;
  justify-content: center;
  line-height: 1.5;
  padding: 10px 5px 5px;
}

.powers_container {
  background: white;
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.power {
  align-items: center;
  background: #eceff3;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
  position: relative;
}

.power_attr {
  padding-left: 15px;
  width: 80px;
}

.power_value {
  background: var(--primary-color-dark);
  border-radius: 0 10px 10px 0;
  color: #eceff3;
  height: 100%;
  right: 0;
  text-align: center;
  top: 0;
  width: 48px;
}

.power_gauge {
  background-color: rgb(199, 199, 199);
  flex-grow: 2;
  height: 10px;
}

.power_gauge_pointer {
  background-color: var(--color-cta-primary);
  height: 100%;
}

.remove_card {
  background-image: url('./img/delete_icon.png') , url('../../img/black_texture.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% , cover;
  border: none;
  border-radius: 0 10px;
  height: 40px;
  margin: 10px 10px 0 0;
  width: 40px;
}

@media screen and ( min-width : 600px ) {

  .card {

    --card-width: 320px;

  }
}
