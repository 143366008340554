/* stylelint-disable font-family-name-quotes */
.form_new_card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  justify-content: space-between;
}

.card_front {
  background: white;
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgb(0 0 0);
  height: 100%;
  overflow: hidden;
  padding: 50px 20px 20px;
  position: relative;
  width: 100%;
}

.header_card_front {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.heder_card_title {
  flex-grow: 2;
  font-size: 18px;
  font-weight: 900;
  padding-left: 20px;
}


.footer_form_card {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.rare_input {
  flex-grow: 1;
}

.super_trunfo {
  display: flex;
  flex-grow: 1;
  gap: 17px;
  justify-content: flex-end;
}

.super_trunfo.labelDisabled {
  opacity: 0.5;
}

.has_trunfo_msg {
  color: gray;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.container_power {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 2fr;
  row-gap: 5px;
}

.power_title {
  font-size: 14px;
  font-weight: 400;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
}

.remaining_power {
  color: var(--color-cta-primary);
  font-size: 14px;
  font-weight: 600;
  grid-column: 1 / 3;
  grid-row: 4 / 5;
  text-align: right;
}

.remaining_power_value {
  font-size: 18px;
  font-weight: 900;
}
