/* stylelint-disable font-family-name-quotes */
.label_power {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.label_power.attr1 {
  grid-column: 2 /3;
  grid-row: 1 / 2;
}


.label_power.attr2 {
  grid-column: 2 /3;
  grid-row: 2 / 3;
}

.label_power.attr3 {
  grid-column: 2 /3;
  grid-row: 3 / 4;
}

.label_power_title {
  flex-grow: 1;
  font-weight: 400;
  height: 30px;
  margin-left: 40px;
  position: relative;
}

.attr1:before, .attr2:before, .attr3:before {
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  height: 26px;
  left: -36px;
  position: absolute;
  top: 0;
  width: 26px;
}


.attr1:before {
  background-image: url('./img/power.png');
  background-size: 70%;
}

.attr2:before {
  background-image: url('./img/defesa.png');
  background-size: 70%;
}

.attr3:before {
  background-image: url('./img/agilidade.png');
  background-size: 80%;
}

.number_input {
  border: 0;
  color: var(--primary-color);
  font-family: 'Titillium Web' , sans-serif;
  font-size: 19px;
  font-weight: 900;
  padding: 5px;
  row-gap: 5px;
  text-align: center;
}

.decrement_btn, .increment_btn {
  background-color: transparent;
  border: 0;
  color: black;
  font-family: 'Titillium Web' , sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 100%;
  width: 30px;
}

.decrement_btn {
  margin-left: 20px;
}
