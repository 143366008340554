@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@600;700&family=Titillium+Web:wght@300;400;600;700;900&family=Ultra&display=swap');

:root {
  --primary-color: #206152;
  --primary-color-dark: #2F4F4F;
  --primary-color-extra-dark: #023031;
  --primary-color-light: #10a1a3;
  --secondary-color: #d2b48c;

  --color-cta-primary: #DAA520;
  --color-cta-secondary: #8D9909;

  --card-width: 87vw;
  --search-width: 60vw;
  --logo-height: 100px;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-color-dark);
  font-weight: 400;
}

img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Remove a margem padrão */
  display: none; /* Oculta as setas */
}

@media screen and ( min-width : 600px ) {

  :root {

    --card-width: 320px;
    --search-width: 320px
  }
}