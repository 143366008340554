/* stylelint-disable font-weight-notation */

/* stylelint-disable font-family-name-quotes */
.container_app {
  background: url('./img/fundo_site.png');
  background-size: 150px;
  max-width: 100%;
  min-height: 100vh;
  width: 100vw;
}

.container_preview_card {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 150px);
  padding-bottom: 60px;
  padding-top: var(--logo-height);
}


/* para rodar as cartas */
.flip_card {
  height: calc((var(--card-width)) * 8 / 5);
  width: var(--card-width);
}

.flip_card_inner {
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: 1s;
  width: 100%;
}

.flip_card.flipped .flip_card_inner {
  transform: rotateY(-180deg);
}

.card_back, .card_front {
  backface-visibility: hidden;
  left: 0;
  position: absolute;
  top: 0;

  /* width: 100%; */
}

.flip_card .card_back {
  transform: rotateY(-180deg);
}


/* Início da seção que mostra as cartas salvas */
.container_saved_card {
  background-image: url('./img/black_texture.png');
  margin-top: 50px;
  min-height: calc((var(--card-width)) * 8 / 5);
  padding: 40px 0 75px;
  position: relative;
  width: 100%;
}

.container_saved_card .card_back {
  position: initial;
}

/* .deck_header {
  background-color: tomato;
} */

.card_saved_title_section {
  color: var(--color-cta-primary);
  font-family: 'MuseoModerno' , cursive;
  font-size: 35px;

  /* padding-bottom: 20px; */
  text-align: center;
  text-shadow: 3px 2px 4px #000;
}

.about_playing_cards {
  color: var(--color-cta-primary);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  padding: 0 60px;
  text-align: center;
}

.playing_cards {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  min-height: calc((var(--card-width)) * 8 / 5);
  padding: 20px 0;
  width: 100%;
}

.nav_game {
  bottom: 0;
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 3;
}

