.preview_card, .edit_card {
  align-items: center;
  background-color: var(--color-cta-secondary);
  border: none;
  border-radius: 0 10px;
  color: var(--primary-color-extra-dark);
  display: flex;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  padding: 10px 50px 10px 10px;
  position: relative;
  width: 164px;
}

.preview_card:before, .preview_card:after {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  height: 30px;
  position: absolute;
  width: 30px;
}

.preview_card:before {
  background-image: url('./border_inverse/border_inverse.svg');
  left: -27px;
  top: -3px;
}

.preview_card:after {
  background-image: url('./border_inverse/border_inverse.svg');
  bottom: -27px;
  right: -3px;
}


/* .edit_card:before {
  background-image: url('./border_inverse/border_inverse_withe.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  height: 30px;
  position: absolute;
  width: 30px;
} */

.preview_card p, .edit_card p {
  align-items: center;
  display: flex;
  font-family: Titillium Web , Roboto , sans-serif;
  height: 40px;
  justify-content: center;
  position: relative;
}

.preview_card p:after, .edit_card p:after {
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  height: 40px;
  position: absolute;
  right: -40px;
  top: 0;
  width: 40px;
}


.preview_card p:after {
  background-image: url('./icon/view.png');
  background-size: 70%;
}


.edit_card p:after {
  background-image: url('./icon/edit.png');
  background-size: 50%;
}
