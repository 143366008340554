.container_menu {

  --size_item: 70px;

  --menu_height: calc(var(--size_item) + 20px);

  /* background-color: violet; */
  bottom: 0;
  height: var(--menu_height);
  position: fixed;
  transform: translateY(var(--menu_height));
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition-timing-function: linear;
  width: 100%;
  z-index: 3;
}

.container_menu.show_menu {

  /* background-color: olive; */
  transform: translateY(var(--menu_height) * -1);
}

.menu, .menu_item {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--size_item);
  justify-content: center;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-indent: -1000px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--size_item);
}

.menu {
  background: var(--color-cta-primary);
  background-image: url('./img/cartas.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
  border: none;
  z-index: 4;
}

.menu_mask {
  background-color: rgb(0 0 0 / 30%);
  border: none;
  height: 100vh;
  position: absolute;
  text-indent: -2000vw;
  top: var(--menu_height);
  width: 100vw;

}

.search, .play, .info {
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.search {
  background-image: url('./img/lupa.png') , url('../../img/black_texture.png');
  background-size: 45% , cover;
  transition: all 0.2s 0.1s ease-in-out;
}

.play {
  background-image: url('./img/play.png') , url('../../img/black_texture.png');
  background-size: 40% , cover;
  transition: all 0.2s 0.2s ease-in-out;
}

.info {
  background-image: url('./img/github.png') , url('../../img/black_texture.png');
  background-size: 50% , cover;
  transition: all 0.2s 0.3s ease-in-out;
}

/* Estado dos botoes quando ativos */
.menu.active ~ .menu_mask {
  top: calc((100vh - var(--menu_height)) * -1);
}

.search.active {
  transform: translate(-155%, -120%);
}

.play.active {
  transform: translate(-50%, -170%);
}

.info.active {
  transform: translate(55%, -120%);
}
