.empty_deck_msg {
  align-items: center;
  background: var(--color-cta-primary);
  bottom: 0;
  display: flex;
  height: 50px;
  justify-content: center;
  position: fixed;
  transition: 0.6s;
  transition-timing-function: linear;
  width: 100%;
  z-index: 3;
}

.empty_deck_msg.escondido {
  transform: translateY(50px);
}
